import { toast } from 'react-toastify';

import { tags as applicationTags } from './application';
import { apiSlice } from '../apiSlice';
import axios from '../../utils/axios';

const tags = {
  documents: 'documents',
};

const documentsApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      getDocuments: build.query({
        queryFn: async () => {
          try {
            const response = await axios({
              url: `${baseURL}/documents`,
              method: 'GET',
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [tags.documents],
      }),



      uploadDocuments: build.mutation({
        queryFn: async (payload) => {
          const { type, file } = payload;
          try {
            const formData = new FormData();
            formData.append('document', file);
            const response = await axios({
              url: `${baseURL}/documents/upload/${type}`,
              method: 'POST',
              data: formData,
              headers: { 'content-type': 'multipart/form-data' },
            });
            toast.success('Document Uploaded');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [...Object.values(tags), applicationTags.profile, applicationTags.afrProfile]), // invalidates applicant and afrProfile as well
      }),



      downloadDocument: build.query({
        queryFn: async ({ document_uuid }) => {
          try {
            const response = await axios({
              url: `${baseURL}/documents/download/${document_uuid}`,
              method: 'GET',
              responseType: 'blob',
            });
            const a = document.createElement('a');
            const [, fileName] = response.headers['content-disposition'].split('filename=');
            a.download = decodeURI(fileName);
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.append(a);
            a.click();
            return { data: fileName };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      downloadTemplateDocument: build.query({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/document-templates/${payload}`,
              method: 'GET',
              responseType: 'blob',
            });
            const a = document.createElement('a');
            const [, fileName] = response.headers['content-disposition'].split('filename=');
            a.download = decodeURI(fileName);
            a.href = window.URL.createObjectURL(new Blob([response.data]));
            document.body.append(a);
            a.click();
            return { data: fileName };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong. Please try again.');
            return { error: error.message };
          }
        },
        providesTags: [],
      }),



      uploadManualSignature: build.mutation({
        queryFn: async ({ file }) => {
          try {
            const formData = new FormData();
            formData.append('document', file);
            const response = await axios({
              url: `${baseURL}/documents/upload/signatures/manual`,
              method: 'POST',
              data: formData,
              headers: { 'content-type': 'multipart/form-data' },
            });
            toast.success('Document Uploaded');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [...Object.values(tags), applicationTags.profile, applicationTags.afrProfile]), // invalidates applicant and afrProfile as well
      }),



      uploadAFRManualSignature: build.mutation({
        queryFn: async ({ file, afr_uuid }) => {
          try {
            const formData = new FormData();
            formData.append('document', file);
            const response = await axios({
              url: `${baseURL}/afr/signatures/upload/manual/${afr_uuid}`,
              method: 'POST',
              data: formData,
              headers: { 'content-type': 'multipart/form-data' },
            });
            toast.success('Document Uploaded');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [...Object.values(tags), applicationTags.profile, applicationTags.afrProfile]), // invalidates applicant and afrProfile as well
      }),

    }),
  });

export const {
  useGetDocumentsQuery,
  useLazyDownloadTemplateDocumentQuery,
  useUploadDocumentsMutation,
  useLazyDownloadDocumentQuery,
  useUploadManualSignatureMutation,
  useUploadAFRManualSignatureMutation,
} = documentsApi;
